<template>
  <div class="flex h-full items-center justify-center border border-white bg-white/30 text-center text-white backdrop-blur-sm">
    <!-- TODO: Jira WEN3-70 - Aruba check inschakelen na livegang -->
    <div
      v-if="true || !isAvailable"
      class="max-w-xs p-6"
    >
      <div class="font-dia mb-3 text-3xl font-black leading-[0.8] tracking-tighter md:text-4xl lg:text-6xl">
        {{ title }}
      </div>
      <p class="mb-2 text-lg font-bold leading-none lg:text-xl">
        {{ text }}
      </p>
      <FormKit
        v-slot="{state}"
        autocomplete="off"
        type="form"
        method="POST"
        :actions="false"
        @submit="submit"
      >
        <div class="space-y-4">
          <FormKit
            name="event"
            type="select"
            :options="defaultEvents"
            :placeholder="$t('section_hero.cta_free_wifi.select_placeholder')"
            value-type="object"
            input-class="text-black"
            label-class="$reset text-left"
            wrapper-class="text-left"
            validation="required"
          />

          <UiButton
            :label="buttonLabel"
            type="submit"
            :icon="buttonIcon"
            class="w-full disabled:pointer-events-none disabled:border-gray-300 disabled:bg-gray-300"
            :disabled="!state.complete || !state.valid"
          />
        </div>
      </FormKit>
    </div>
    <div
      v-else
      class="max-w-xs p-6"
    >
      <div class="font-dia mb-3 text-3xl font-black leading-[0.8] tracking-tighter md:text-4xl lg:text-6xl">
        {{ $t('aruba.no_access.title') }}
      </div>
      <p class="mb-2 text-lg font-bold leading-none lg:text-xl">
        {{ $t('aruba.no_access.description') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {Event} from '@graphql/graphql';

defineProps<{
  title: string,
  text: string,
  buttonLabel: string,
  buttonIcon?: string,
}>();

const {isAvailable, login} = useAruba();

const defaultEvents = [
  {
    label: 'Beurs / Evenement',
    value: 'beurs_evenement',
  },
  {
    label: 'Congres / Vergadering',
    value: 'congres_vergadering',
  },
  {
    label: 'Horeca',
    value: 'horeca',
  },
  {
    label: 'Theater',
    value: 'theater',
  },
];

function submit({event}: { event: number | string | Event }) {
  if (typeof event === 'object' && event?.is_third_party_event) {
    return navigateTo({name: 'wifi-connect'});
  }

  login();
}
</script>
